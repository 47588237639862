import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div className='content'>
              <h1 className='hehe'>
                  hehe
              </h1>
              <h1 className='xd'>
                  xd
              </h1>
          </div>
      </header>
    </div>
  );
}

export default App;
